import Storage from "@/plugins/storage";
import * as Sentry from "@sentry/browser";
import UtmService from "./UtmService";

/* MOCKS */
jest.mock("@/services/MyRentalsConfig");

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("getUtmsFromUrlOrCookie", () => {
  it("should call methods to extract utms from cookies and url", () => {
    // given
    spyOn(Storage, "getCookiesItem");
    // when
    UtmService.getUtmsFromUrlOrCookie();
    // then
    expect(Storage.getCookiesItem).toHaveBeenCalled();
  });
  it("should return utms as the utms received from cookies", () => {
    // given
    const mockedUtmValue = "test-value-456";
    const mockedUtms = `utmcsr=${mockedUtmValue}|utmcmd=(none)|utmccn=(not set)`;
    const expectedUtm = {
      utmSource: mockedUtmValue,
      utmMedium: "(none)",
      utmCampaign: "(not set)",
      gclid: undefined,
    };

    spyOn(Storage, "getCookiesItem").and.returnValue(mockedUtms);
    const result = UtmService.getUtmsFromUrlOrCookie();
    // when
    // then
    expect(result).toStrictEqual(expectedUtm);
  });
  it("should throw a console.error and a sentry if an error has ocurred", () => {
    // given
    const errorMessage = "test error message";
    spyOn(Storage, "getCookiesItem").and.throwError(errorMessage);
    spyOn(console, "error");
    spyOn(Sentry, "captureException");
    // when
    UtmService.getUtmsFromUrlOrCookie();
    // then
    expect(console.error).toHaveBeenCalledWith(
      "UtmService getUtmsFromUrlOrCookie error"
    );
    expect(Sentry.captureException).toHaveBeenCalledWith(
      `UtmService getUtmsFromUrlOrCookie error: Error: ${errorMessage}`
    );
  });
});
